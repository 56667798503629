import { CartData } from 'src/Elements/Cart/types'

export function hasPhysicalProducts(cart: CartData): boolean {
  return cart.items.some(({ product_id, variant_id }) => {
    const product = globalThis.globalResourceData.productsById[product_id]
    const variant = globalThis.globalResourceData.variantsById[variant_id]
    let productType = variant?.product_type
    productType = productType || product?.product_type
    return productType == 'physical'
  })
}

export function hasSubscriptionProducts(cart: CartData): boolean {
  return cart.items.some(({ price_id }) => {
    const price = globalThis.globalResourceData.pricesById[price_id]
    return price.is_recurring
  })
}
